<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Redirect uri" prop="redirect_uri">
						<el-input v-model="formData.redirect_uri" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Заголовок(ru)" prop="title_ru">
						<el-input v-model="formData.title_ru" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Заголовок (he)" prop="title_uk">
						<el-input v-model="formData.title_uk" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Описание (ru)" prop="description_ru">
						<el-input v-model="formData.description_ru" type="textarea" rows="4" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Описание (he)" prop="description_uk">
						<el-input v-model="formData.description_uk" type="textarea" rows="4" />
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin } from '@/mixins';

export default {
	mixins: [itemFormMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				redirect_uri: '',
				title_ru: '',
				title_uk: '',
				description_ru: '',
				description_uk: ''
			}
		};
	},

	computed: {
		rules: () => ({
			redirect_uri: required,
			title_ru: required,
			title_uk: required,
			description_ru: required,
			description_uk: required
		})
	},

	methods: {}
};
</script>
